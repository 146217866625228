<div class="pho-sidebar" [ngClass]="sidebarCollapse ? 'sidebar-collapse' : ''">
  <div
    class="icon-menu flex items-center justify-center"
    *ngIf="sidebarCollapse"
    (click)="checkSidebarActive()"
  >
    <img src="/assets/images/icon-menu-burger.svg" />
  </div>

  <div
    class="flex items-center justify-content-between experiment-block m-0 p-3"
    *ngIf="!sidebarCollapse"
  >
    <div class="exp-details flex-initial w-4/4">
      <p-skeleton
        width="7rem"
        height="1rem"
        styleClass="head-h4 mb-2"
        *ngIf="experimentDetails.length === 0"
      ></p-skeleton>

      <div class="flex">
        <p-skeleton
          width="2.5rem"
          height="1rem"
          styleClass="head-h5 mr-2"
          *ngIf="experimentDetails.length === 0"
        ></p-skeleton>

        <p-skeleton
          width="2.5rem"
          height="1rem"
          styleClass="head-h5 mr-2"
          *ngIf="experimentDetails.length === 0"
        ></p-skeleton>

        <p-skeleton
          width="2.5rem"
          height="1rem"
          styleClass="head-h5"
          *ngIf="experimentDetails.length === 0"
        ></p-skeleton>
      </div>

      <ng-container *ngIf="experimentData">
        <p class="pt-1 head-h4">{{ experimentData?.name }}</p>
        <ng-container *ngIf="expType === 'pho'">
          <span class="head-h5">{{ experimentData?.year }}</span>
          <span class="head-h5">{{ experimentData?.time_period }}</span>
        </ng-container>

        <span class="head-h5" *ngIf="expType !== 'pho'">{{
          experimentData?.key_accound
        }}</span>
        <span class="head-h5">{{ experimentData?.country }}</span>
        <span
          class="head-h5 last-child"
          *ngIf="expType !== 'pho'"
          title="{{ experimentData?.region }}"
          >{{ experimentData?.region }}</span
        >
      </ng-container>
    </div>
    <div
      class="flex justify-end items-center p-0"
      *ngIf="experimentData?.name && editAccessType"
    >
      <app-edit-btn
        [disabledFlag]="true"
        (actionBtnCallback)="navigateToEdit()"
      ></app-edit-btn>
    </div>
  </div>

  <div class="experiment-name-out" *ngIf="sidebarCollapse">
    <div class="experiment-name">
      {{ exprimentName }}

      <div
        class="edit-icon disabled"
        (click)="navigateToEdit()"
        *ngIf="editAccessType"
      >
        <img src="/assets/images/icon-pencil-black.svg" />
      </div>
    </div>

    <div class="experiment-block m-0 py-2 px-3">
      <div class="exp-details">
        <ng-container *ngIf="experimentData">
          <p class="pt-1 head-h4" title="{{ experimentData?.name }}">
            {{ experimentData?.name }}
          </p>

          <span class="head-h5" *ngIf="expType !== 'pho'">{{
            experimentData?.key_accound
          }}</span>
          <ng-container *ngIf="expType === 'pho'">
            <span class="head-h5">{{ experimentData?.year }}</span>
            <span class="head-h5">{{ experimentData?.time_period }}</span>
          </ng-container>
          <span class="head-h5">{{ experimentData?.country }}</span>
          <span
            class="head-h5 last-child"
            *ngIf="expType !== 'pho'"
            title="{{ experimentData?.region }}"
            >{{ experimentData?.region }}</span
          >
        </ng-container>
      </div>
    </div>
  </div>

  <div
    class="menu-item-out"
    (mouseleave)="sidebarCollapse ? hideMenuItems('planDetails') : ''"
  >
    <p
      class="head-h3 head-icon flex items-center"
      [ngClass]="sidebarCollapse && planDetails.dropShow ? 'active' : ''"
      (mouseenter)="sidebarCollapse ? showMenuItems('planDetails') : ''"
    >
      <img src="/assets/images/{{ planDetails.icon }}" />
      <span class="ml-3" *ngIf="!sidebarCollapse">{{ planDetails.name }}</span>
    </p>
    <div
      [ngClass]="sidebarCollapse ? 'drop-down' : ''"
      *ngIf="sidebarCollapse ? planDetails.dropShow : true"
    >
      <p class="head-h3" *ngIf="sidebarCollapse">{{ planDetails.name }}</p>
      <ul class="pho-plan">
        <li
          *ngFor="let step of steps; let i = index"
          [ngClass]="{
            'drop-item': step?.sub_level,
            'drop-expanded': step?.sub_menu_show
          }"
        >
          <div
            class="w-100 step_{{
              step.status
            }} relative list-none head-h4 nav-item flex items-center justify-between"
            [ngClass]="{
              'is-active': step.slug === level
            }"
            (click)="
              step.status === 'completed' ||
              step.status === 'in_progress' ||
              step?.sub_level
                ? navigateToStep(step)
                : ''
            "
          >
            <span class="d-flex">
              <p class="flex items-center w-9">
                <ng-container *ngIf="!step?.sub_menu_show">
                  <span
                    *ngIf="step.status === 'completed' && !lockAssortment"
                    class="tick-icon"
                  >
                    <app-svg-icon
                      icon="tick-icon"
                      color="#000"
                      padding="6px"
                    ></app-svg-icon>
                  </span>
                  <span
                    *ngIf="step.status !== 'completed' && !lockAssortment"
                    class="h-5 mr-4"
                  >
                    <app-svg-icon
                      icon="inprogress"
                      color="#e4bf00"
                      padding="9.5px"
                    ></app-svg-icon>
                  </span>
                  <span *ngIf="lockAssortment" class="h-5 mr-4">
                    <app-svg-icon
                      icon="lock-icon"
                      color="#e4bf00"
                      padding="9.5px"
                    ></app-svg-icon>
                  </span>
                </ng-container>
              </p>
              <p class="step-label">{{ step.label }}</p>
            </span>

            <span class="pr-4">
              <img
                *ngIf="step?.sub_level && !step?.sub_menu_show"
                src="/assets/images/{{
                  ['completed', 'in_progress'].includes(step.status)
                    ? 'arrow-yellow-down.svg'
                    : 'arrow-gray-down.svg'
                }}"
              />

              <img
                *ngIf="step?.sub_level && step?.sub_menu_show"
                src="/assets/images/{{
                  ['completed', 'in_progress'].includes(step.status)
                    ? 'arrow-yellow-up.svg'
                    : 'arrow-gray-up.svg'
                }}"
              />
            </span>
          </div>

          <ul
            *ngIf="step?.sub_level && step?.sub_menu_show"
            class="sub-menu sub_{{ step.status }}"
          >
            <li
              *ngFor="let subLevel of step?.sub_level"
              class="step_{{
                subLevel.status
              }} relative list-none head-h4 nav-item"
              [ngClass]="{
                'is-active': subLevel.slug === level
              }"
              (click)="
                subLevel.status === 'completed' ||
                subLevel.status === 'in_progress'
                  ? navigateToStep(subLevel)
                  : ''
              "
            >
              <div>
                <span class="d-flex">
                  <p class="flex items-center">
                    <span
                      [ngClass]="
                        subLevel.status === 'completed' && !lockAssortment
                          ? 'tick-icon'
                          : 'h-5 mr-4'
                      "
                    >
                      <app-svg-icon
                        *ngIf="!lockAssortment"
                        [icon]="
                          subLevel.status === 'completed'
                            ? 'tick-icon'
                            : 'inprogress'
                        "
                        [color]="
                          subLevel.status === 'completed' ? '#000' : '#e4bf00'
                        "
                        [padding]="
                          subLevel.status === 'completed' ? '6px' : '9.5px'
                        "
                      ></app-svg-icon>

                      <app-svg-icon
                        *ngIf="lockAssortment"
                        icon="lock-icon"
                        color="#e4bf00"
                        padding="9.5px"
                      ></app-svg-icon>
                    </span>
                  </p>
                  <p class="step-label">
                    {{ subLevel.label }}
                  </p>
                </span>
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>

  <!-- Space Secondary Menu -->
  <ng-container *ngIf="domainType === 'space'">
    <div
      *ngIf="spaceSteps.length > 0"
      class="menu-item-out"
      (mouseleave)="sidebarCollapse ? hideMenuItems('spaceDetails') : ''"
    >
      <p
        class="head-h3 head-icon flex items-center"
        [ngClass]="sidebarCollapse && spaceDetails.dropShow ? 'active' : ''"
        (mouseenter)="sidebarCollapse ? showMenuItems('spaceDetails') : ''"
      >
        <img src="/assets/images/{{ spaceDetails.icon }}" />
        <span class="ml-3" *ngIf="!sidebarCollapse">{{
          spaceDetails.name
        }}</span>
      </p>
      <div
        [ngClass]="sidebarCollapse ? 'drop-down' : ''"
        *ngIf="sidebarCollapse ? spaceDetails.dropShow : true"
      >
        <p class="head-h3" *ngIf="sidebarCollapse">{{ spaceDetails.name }}</p>
        <ul class="exe-summary">
          <li
            *ngFor="let step of spaceSteps; let i = index"
            class="step_{{ step.status }} head-h4 nav-item"
            (click)="navigateToStep(step)"
            [ngClass]="{
              'is-active': step?.slug_group?.includes(level)
            }"
          >
            <span class="d-flex">
              <p class="step-label">{{ step.label }}</p>
            </span>
          </li>
        </ul>
      </div>
    </div>
  </ng-container>

  <!-- PHO Secondary Menu -->
  <ng-container *ngIf="domainType === 'pho'">
    <div
      *ngIf="phoSecondarySteps.length > 0"
      class="menu-item-out"
      (mouseleave)="sidebarCollapse ? hideMenuItems('phoDetails') : ''"
    >
      <p
        *ngIf="sidebarCollapse"
        class="head-h3 head-icon flex items-center"
        [ngClass]="sidebarCollapse && phoDetails.dropShow ? 'active' : ''"
        (mouseenter)="sidebarCollapse ? showMenuItems('phoDetails') : ''"
      >
        <img src="/assets/images/{{ phoDetails.icon }}" />
      </p>
      <div
        [ngClass]="sidebarCollapse ? 'drop-down' : ''"
        *ngIf="sidebarCollapse ? phoDetails.dropShow : true"
      >
        <!-- <p class="head-h3" *ngIf="sidebarCollapse">{{ phoDetails.name }}</p> -->
        <ul class="exe-summary">
          <li
            *ngFor="let step of phoSecondarySteps; let i = index"
            class="step_{{ step.status }} head-h3 nav-item"
            (click)="navigateToStep(step)"
            [ngClass]="{
              'is-active': step.slug === level
            }"
          >
            <span class="d-flex">
              <p class="step-label">{{ step.label }}</p>
            </span>
          </li>
        </ul>
      </div>
    </div>
  </ng-container>

  <div class="btn-arrow-expand" (click)="checkSidebarActive()">
    <span *ngIf="!sidebarCollapse"
      ><img src="/assets/images/icon-arrow-collapse.svg"
    /></span>
    <span *ngIf="sidebarCollapse"
      ><img src="/assets/images/icon-arrow-collapse.svg"
    /></span>
  </div>
</div>
