<button
  class="btn"
  [ngClass]="{
    'is-active': toggleOnOff
  }"
  pTooltip="{{ tooltipText }}"
  tooltipPosition="top"
  (click)="clickEvent()"
>
  <app-svg-icon icon="{{ icons }}" padding="10px" color="#000"></app-svg-icon>
</button>
