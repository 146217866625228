import { CatExpertRouterLink } from "../../../app-routing-enum";

export const VotingStatic = {
  breadCrumbs: [
    {
      title: "Voting",
      slug: `${CatExpertRouterLink.Edit}`,
      expId: sessionStorage.getItem("expId"),
    },
    {
      title: "Voting & Tracking",
      slug: `${CatExpertRouterLink.Eda}`,
      expId: sessionStorage.getItem("expId"),
    },
  ],
  usersTableColumns: [
    {
      label: "Name",
      value: "name",
      filterType: "input_text",
      sortable: true,
      filterMatchMode: "contains",
    },
    {
      label: "Email ID",
      value: "email_id",
      filterType: "input_text",
      sortable: true,
      filterMatchMode: "contains",
    },
    {
      label: "Department",
      value: "department",
      filterType: "dropdown",
      filterOptions: [],
      sortable: true,
    },
    {
      label: "Voting Status",
      value: "voting_status",
      filterType: "dropdown",
      filterOptions: [],
      sortable: true,
    },
    {
      label: "Action",
      value: "actions",
    },
  ],
  userTableActions: [
    {
      label: "",
      action: "user-voting-reset",
      btnIcon: "reset-black",
      tooltip: "Reset",
    },
    {
      label: "",
      action: "send-notification",
      btnIcon: "notif",
      tooltip: "Send Notification",
    },
    {
      label: "",
      action: "remove-user-voting",
      btnIcon: "trash",
      tooltip: "Delete",
    },
  ],
  levelsList: [
    {
      label: "Overall",
      value: "overall",
    },
    {
      label: "Innovation",
      value: "innovation",
    },
    {
      label: "Non Innovation",
      value: "non_innovation",
    },
  ],
  userVotingTableColumns: [
    {
      label: "SKU Name",
      value: "sku_detail",
      sortable: true,
      filterType: "input_text",
      filterMatchMode: "contains",
    },
    {
      label: "Total Volume",
      value: "total_volume",
      subHeading: "HL",
      sortable: true,
      filterType: "input_text",
    },
    {
      label: "Total MACO",
      value: "total_maco",
      subHeading: "$",
      sortable: true,
      filterType: "input_text",
    },
    {
      label: "Total Revenue",
      value: "total_revenue",
      subHeading: "$",
      sortable: true,
      filterType: "input_text",
    },
    {
      label: "PTC Segment",
      value: "ptc_segment",
      sortable: true,
      filterType: "dropdown",
      filterOptions: [],
    },
    {
      label: "MACO",
      value: "maco_hl",
      subHeading: "HL",
      sortable: true,
      filterType: "input_text",
    },
    {
      label: "MACO Margin",
      value: "maco_margin",
      subHeading: "%",
      sortable: true,
      filterType: "input_text",
    },
    {
      label: "Growth",
      value: "growth",
      subHeading: "YOY %",
      sortable: true,
      filterType: "input_text",
    },
    {
      label: "SKU Ranking Score",
      value: "sku_ranking_score",
      valueType: "",
      sortable: true,
      filterType: 'input_text',
    },
    {
      label: "Volume Retained",
      value: "volume_retained",
      sortable: true,
      filterType: "input_text",
    },
    {
      label : "ABI Share",
      value : "share_abi",
      sortable: true,
      filterType: "input_text",
    },
    {
      label : "Revenue Uplift",
      value : "revenue_uplift",
      sortable: true,
      filterType: "input_text",
    },
    {
      label : "MACO Uplift",
      value : "maco_uplift",
      sortable: true,
      filterType: "input_text",
    },
    {
      label: "Recommendation",
      value: "recommendation",
      sortable: true,
      filterType: "input_text",
      filterMatchMode: "contains",
    },
    {
      label: "Reason",
      value: "reason",
      filterType: "input_text",
      filterMatchMode: "contains",
    },
    {
      label: "Voting Decision",
      value: "vote",
    },
  ],
  userVotingTableActions: [
    // {
    //   label: "",
    //   action: "keep",
    //   btnIcon: "tick",
    //   tooltip: "Keep",
    // },
    // {
    //   label: "",
    //   action: "delist",
    //   btnIcon: "cross",
    //   tooltip: "Delist",
    // },
  ],
  macoLineChartOptions: {
    stacked: false,
    maintainAspectRatio: false,
    aspectRatio: 0.6,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: {
          color: "gray",
        },
        grid: {
          color: "#efefef",
        },
      },
      y: {
        type: "linear",
        display: true,
        position: "left",
        ticks: {
          color: "gray",
        },
        grid: {
          color: "#efefef",
        },
        title: {
          display: true,
          text: "MACO Margin (%) & Country MACO Margin (%)",
        },
      },
    },
  },
  revenueLineChartOptions: {
    stacked: false,
    maintainAspectRatio: false,
    aspectRatio: 0.6,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: {
          color: "gray",
        },
        grid: {
          color: "#efefef",
        },
      },
      y: {
        type: "linear",
        display: true,
        position: "left",
        ticks: {
          color: "gray",
        },
        grid: {
          color: "#efefef",
        },
        title: {
          display: true,
          text: "Revenue",
        },
      },
      y1: {
        type: "linear",
        display: true,
        position: "right",
        ticks: {
          color: "gray",
        },
        grid: {
          color: "#efefef",
        },
        title: {
          display: true,
          text: "Volume",
        },
      },
    },
  },
  votingSummaryChartData: {
    labels: ["Keep", "Delist"],
    datasets: [
      {
        label: "",
        data: [40, 25],
        backgroundColor: ["#6EBC14", "#D14447"],
        borderColor: ["#6EBC14", "#D14447"],
        borderWidth: 1,
      },
    ],
  },
  votingSummaryChartOptions: {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context: any) {
            return context.formattedValue + "%";
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: "gray",
          min: 0,
          max: 100,
          stepSize: 5,
          callback: function (label: any) {
            return label + "%";
          },
        },
        grid: {
          color: "#EFEFEF",
          drawBorder: false,
        },
      },
      x: {
        ticks: {
          color: "gray",
        },
        grid: {
          color: "#EFEFEF",
          drawBorder: false,
        },
      },
    },
  },
  macoLineChartData: {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        keyValue: "maco_margin",
        label: "MACO Margin",
        fill: false,
        borderColor: "#355070",
        yAxisID: "y",
        tension: 0,
        borderWidth: 1,
        data: [150, 130, 270, 310, 290, 308, 306, 296, 250, 370, 396, 394, 470],
      },
      {
        keyValue: "segment_maco_margin",
        label: "Country MACO Margin",
        fill: false,
        borderColor: "#E0842F",
        yAxisID: "y",
        tension: 0,
        borderWidth: 1,
        data: [120, 170, 350, 300, 290, 360, 270, 310, 305, 330, 300, 310, 380],
      },
    ],
  },
  revenueLineChartData: {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        keyValue: "revenue",
        label: "Revenue",
        fill: false,
        borderColor: "#355070",
        yAxisID: "y",
        tension: 0,
        borderWidth: 1,
        data: [150, 130, 270, 310, 290, 308, 306, 296, 250, 370, 396, 394, 470],
      },
      {
        keyValue: "volume",
        label: "Volume",
        fill: false,
        borderColor: "#E0842F",
        yAxisID: "y1",
        tension: 0,
        borderWidth: 1,
        data: [120, 170, 350, 300, 290, 360, 270, 310, 305, 330, 300, 310, 380],
      },
    ],
  },
};
