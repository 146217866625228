import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { TableRowCollapseEvent } from "primeng/table";
import { StateService } from "../../services/state.service";

@Component({
  selector: "app-expandable-table",
  templateUrl: "./expandable-table.component.html",
  styleUrl: "./expandable-table.component.scss",
})
export class ExpandableTableComponent implements OnInit, OnChanges {
  @Input() tableColumns: any[] = [];
  @Input() tableData: any[] = [];
  expandedRows: any = {};
  @Input() dataFrom!: 'eda' | 'executive-summary';
  @Input() withFinancialImpact: any;

  constructor(private stateService: StateService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tableData?.currentValue) {
      this.tableData = changes?.tableData?.currentValue;
    }
    if (changes.tableColumns?.currentValue) {
      this.tableColumns = changes?.tableColumns?.currentValue;
    }
  }

  ngOnInit(): void {}

  getColumnWidth(index: number): string {
    if (index === 0) {
      return "0px";
    } else if (index === 1) {
      return "80px";
    } else {
      return "60px";
    }
  }

  toFixedToZeroDigits(val: any) {
    if (typeof val == "string") {
      return val != "" ? val : "-";
    } else if (!isNaN(val)) {
      return this.formatNumber(val.toFixed(0));
    } else {
      return val || "-";
    }
  }

  formatNumber(num: number) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }
}
