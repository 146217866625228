import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { Router } from "@angular/router";
import { StateService } from "./state.service";
@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router) {}
  canActivate(): boolean {
    if (
      !window.localStorage.c ||
      !window.localStorage.i ||
      !window.localStorage.d
    ) {
      return true;
    }

    this.router.navigateByUrl("/studio");
    return false;
  }
}
@Injectable({
  providedIn: "root",
})
export class VerifyAuthGuard implements CanActivate {
  constructor(private router: Router) {}
  canActivate(): boolean {
    if (
      window.localStorage.c ||
      window.localStorage.i ||
      window.localStorage.d
    ) {
      return true;
    }

    this.router.navigateByUrl("/login");
    return false;
  }
}

@Injectable({
  providedIn: "root",
})
export class VerifyPHODomain implements CanActivate {
  constructor(
    private router: Router,
    public stateService: StateService
  ) {}

  canActivate(): boolean {
    if (this.stateService.checkAppConfig() === "pho") {
      return true;
    }

    this.router.navigateByUrl("/studio");
    return false;
  }
}

@Injectable({
  providedIn: "root",
})
export class VerifyCatexpertDomain implements CanActivate {
  constructor(
    private router: Router,
    public stateService: StateService
  ) {}

  canActivate(): boolean {
    if (this.stateService.checkAppConfig() === "catexpert" || "space") {
      return true;
    }

    this.router.navigateByUrl("/studio");
    return false;
  }
}

@Injectable({
  providedIn: "root",
})
export class VerifySpaceDomain implements CanActivate {
  constructor(
    private router: Router,
    public stateService: StateService
  ) {}

  canActivate(): boolean {
    if (this.stateService.checkAppConfig() === "space") {
      return true;
    }

    this.router.navigateByUrl("/studio");
    return false;
  }
}
