import { CdkAccordionModule } from "@angular/cdk/accordion";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  MatDialogModule,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";

import { MatIconModule } from "@angular/material/icon";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { NgSelectModule } from "@ng-select/ng-select";
import { ButtonModule } from "primeng/button";
import { ChartModule } from "primeng/chart";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { DropdownModule } from "primeng/dropdown";
import { MenuModule } from "primeng/menu";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { PaginatorModule } from "primeng/paginator";
import { ProgressBarModule } from "primeng/progressbar";
import { SplitButtonModule } from "primeng/splitbutton";
import { StepsModule } from "primeng/steps";
import { TableModule } from "primeng/table";
import { ToastModule } from "primeng/toast";
import { MultiSelectModule } from "primeng/multiselect";
import { FiTitleCasePipe } from "../pipes/fititle-case.pipe";
import { RemoveTextPipe } from "../pipes/remove-text.pipe";
import { TitleCasePipe } from "../pipes/title-case.pipe";
import { TitleFirstLetterCapsPipe } from "../pipes/title-first-letter-caps.pipe";
import { CommaSeparatePipe } from "../pipes/comma-separate.pipe";
import { CommonModalComponent } from "./common-modal/common-modal.component";
import { FormErrorComponent } from "./form-error/form-error.component";
import { LoaderComponent } from "./loader/loader.component";
import { MessagePopupComponent } from "./message-popup/message-popup.component";
import { ScreenGuideComponent } from "./screen-guide/screen-guide.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { StatusMessageComponent } from "./status-message/status-message.component";
import { ToastDownloadComponent } from "./toast-download/toast-download.component";
import { UploadDownloadComponent } from "./upload-download/upload-download.component";
import { FilterPipe } from "./filter.pipe";
import { TranslateModule } from "@ngx-translate/core";
import { SliderModule } from "primeng/slider";
import { SoftWarningToastComponent } from "./soft-warning-toast/soft-warning-toast.component";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { SkeletonModule } from "primeng/skeleton";
import { AdminModalComponent } from "./admin-modal/admin-modal.component";
import { CheckboxModule } from "primeng/checkbox";
import { AdminTableComponent } from "./admin-table/admin-table.component";
import { AutoCompleteModule } from "primeng/autocomplete";
import { CardModule } from "primeng/card";
import { TabViewModule } from "primeng/tabview";
import { BadgeModule } from "primeng/badge";
import { MatButtonModule } from "@angular/material/button";
import { MatChipsModule } from "@angular/material/chips";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatSliderModule } from "@angular/material/slider";
import { SidebarModule } from "primeng/sidebar";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatSelectModule } from "@angular/material/select";
import { DialogModule } from "primeng/dialog";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatStepperModule } from "@angular/material/stepper";
import { MatRadioModule } from "@angular/material/radio";
import { ConfirmationService } from "primeng/api";
import { TabMenuModule } from "primeng/tabmenu";
import { RadioButtonModule } from "primeng/radiobutton";
import { UIChart } from "primeng/chart";
import { MatMenuModule } from "@angular/material/menu";
import { ChipsModule } from "primeng/chips";
import { CustomDatePickerModule } from "./custom-date-picker/custom-date-picker.module";
import { ShareExperimentComponent } from "./share-experiment/share-experiment.component";
import { SvgIconComponent } from "./svg-icon/svg-icon.component";
import { PhoTableComponent } from "./pho-table/pho-table.component";
import { PhoSidebarComponent } from "../shared/pho-sidebar/pho-sidebar.component";
import { PhoSidebarFormComponent } from "./pho-sidebar-form/pho-sidebar-form.component";
import { ButtonPrimaryComponent } from "./button-primary/button-primary.component";
import { ButtonSecondaryComponent } from "./button-secondary/button-secondary.component";
import { PortfolioAccordionComponent } from "./portfolio-accordion/portfolio-accordion.component";
import { AccordionModule } from "primeng/accordion";
import { ChipModule } from "primeng/chip";
import { NotFoundComponent } from "./not-found/not-found.component";
import { FooterNavigationComponent } from "./footer-navigation/footer-navigation.component";
import { SelectDropdownComponent } from "./select-dropdown/select-dropdown.component";
import { SliderBarComponent } from "./slider-bar/slider-bar.component";
import { TableInputComponent } from "./table-input/table-input.component";
import { PlanogramFooterComponent } from "./planogram-footer/planogram-footer.component";
import { MultiSelectComponent } from "./multi-select/multi-select.component";
import { EditBtnComponent } from "../core/common-components/edit-btn/edit-btn.component";
import { VersionHistoryComponent } from "./version-history/version-history.component";
import { OrdinalDatePipe } from "../pipes/ordinal-date.pipe";
import { VersionConfirmComponent } from "./version-confirm/version-confirm.component";
import { FlowChartComponent } from "./flow-chart/flow-chart.component";
import { UploadFileComponent } from "../core/common-components/upload-file/upload-file.component";
import { MultiSliderComponent } from "./multi-slider/multi-slider.component";
import { EditAlertPopupComponent } from "./edit-alert-popup/edit-alert-popup.component";
import { EditModifyButtonComponent } from "../core/common-components/edit-modify-button/edit-modify-button.component";
import { ButtonToggleComponent } from "./button-toggle/button-toggle.component";
import { KeepButtonComponent } from "../core/common-components/keep-button/keep-button.component";
import { DelistButtonComponent } from "../core/common-components/delist-button/delist-button.component";
import { DatePickerComponent } from "../core/common-components/date-picker/date-picker.component";
import { CalendarModule } from "primeng/calendar";
import { AutoCompleteInputComponent } from "../core/common-components/autocomplete-input/autocomplete-input.component";
import { AutocompleteEmailInputComponent } from "../core/common-components/autocomplete-email-input/autocomplete-email-input.component";
import { KpiCardsComponent } from "../shared/kpi-cards/kpi-cards.component";
import { ShareButtonComponent } from "../core/common-components/share-button/share-button.component";
import { FilterPanelComponent } from "../core/common-components/filter-panel/filter-panel.component";
import { SkuDetailsComponent } from "./sku-details/sku-details.component";
import { ExpandableTableComponent } from "./expandable-table/expandable-table.component";
import { ChartHighchartsComponent } from "./chart-highcharts/chart-highcharts.component";
import { DownloadBtnComponent } from "../core/common-components/download-btn/download-btn.component";

@NgModule({
  declarations: [
    AutoCompleteInputComponent,
    SvgIconComponent,
    DatePickerComponent,
    KeepButtonComponent,
    DelistButtonComponent,
    DownloadBtnComponent,
    FormErrorComponent,
    LoaderComponent,
    CommonModalComponent,
    MessagePopupComponent,
    UploadDownloadComponent,
    TitleCasePipe,
    FiTitleCasePipe,
    TitleFirstLetterCapsPipe,
    CommaSeparatePipe,
    RemoveTextPipe,
    StatusMessageComponent,
    SidebarComponent,
    ScreenGuideComponent,
    ToastDownloadComponent,
    FilterPipe,
    SoftWarningToastComponent,
    AdminModalComponent,
    AdminTableComponent,
    ShareExperimentComponent,
    PhoTableComponent,
    PortfolioAccordionComponent,
    PhoSidebarComponent,
    PhoSidebarFormComponent,
    ButtonPrimaryComponent,
    ButtonSecondaryComponent,
    NotFoundComponent,
    FooterNavigationComponent,
    SelectDropdownComponent,
    SliderBarComponent,
    SelectDropdownComponent,
    TableInputComponent,
    PlanogramFooterComponent,
    MultiSelectComponent,
    EditBtnComponent,
    VersionHistoryComponent,
    VersionConfirmComponent,
    FlowChartComponent,
    OrdinalDatePipe,
    UploadFileComponent,
    MultiSliderComponent,
    EditAlertPopupComponent,
    EditModifyButtonComponent,
    ButtonToggleComponent,
    AutocompleteEmailInputComponent,
    KpiCardsComponent,
    ShareButtonComponent,
    FilterPanelComponent,
    SkuDetailsComponent,
    ExpandableTableComponent,
    ChartHighchartsComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule,
    MatSidenavModule,
    TableModule,
    DropdownModule,
    ChartModule,
    StepsModule,
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule,
    OverlayPanelModule,
    PaginatorModule,
    MatSlideToggleModule,
    MatDialogModule,
    ButtonModule,
    CdkAccordionModule,
    ScrollingModule,
    MatTooltipModule,
    ToastModule,
    ProgressBarModule,
    MatTabsModule,
    SplitButtonModule,
    MenuModule,
    ConfirmDialogModule,
    MultiSelectModule,
    SliderModule,
    SkeletonModule,
    CheckboxModule,
    MatProgressSpinnerModule,
    AutoCompleteModule,
    CardModule,
    TabViewModule,
    BadgeModule,
    MatButtonModule,
    MatChipsModule,
    DialogModule,
    CustomDatePickerModule,
    MatCheckboxModule,
    MatSliderModule,
    AccordionModule,
    ChipModule,
    MatProgressBarModule,
    MatSliderModule,
    MatToolbarModule,
    TranslateModule,
    MatRadioModule,
    OverlayPanelModule,
    MatSelectModule,
    TableModule,
    DialogModule,
    MatCheckboxModule,
    MatTooltipModule,
    TabMenuModule,
    TabViewModule,
    DropdownModule,
    RadioButtonModule,
    CheckboxModule,
    ToastModule,
    MatTabsModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    CardModule,
    SkeletonModule,
    MenuModule,
    MatMenuModule,
    SidebarModule,
    ChipsModule,
    MatStepperModule,
    CalendarModule
  ],
  exports: [
    MatIconModule,
    MatSidenavModule,
    TableModule,
    DropdownModule,
    TranslateModule,
    ChartModule,
    StepsModule,
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule,
    OverlayPanelModule,
    FormErrorComponent,
    PaginatorModule,
    MatDialogModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    MatSliderModule,
    MatToolbarModule,
    TranslateModule,
    MatRadioModule,
    OverlayPanelModule,
    MatSelectModule,
    TableModule,
    DialogModule,
    MatCheckboxModule,
    MatTooltipModule,
    TabMenuModule,
    TabViewModule,
    DropdownModule,
    RadioButtonModule,
    CheckboxModule,
    ToastModule,
    MatTabsModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    CardModule,
    SkeletonModule,
    MenuModule,
    MatMenuModule,
    SidebarModule,
    ChipsModule,
    MatStepperModule,
    MatProgressBarModule,
    MessagePopupComponent,
    ToastDownloadComponent,
    CommonModalComponent,
    LoaderComponent,
    UploadDownloadComponent,
    SoftWarningToastComponent,
    TitleCasePipe,
    FiTitleCasePipe,
    ButtonModule,
    TitleFirstLetterCapsPipe,
    CommaSeparatePipe,
    RemoveTextPipe,
    StatusMessageComponent,
    CdkAccordionModule,
    ScrollingModule,
    MatTooltipModule,
    SidebarComponent,
    ScreenGuideComponent,
    ToastModule,
    ProgressBarModule,
    MatTabsModule,
    FilterPipe,
    SplitButtonModule,
    MenuModule,
    ConfirmDialogModule,
    MultiSelectModule,
    SliderModule,
    MatTooltipModule,
    SkeletonModule,
    AdminTableComponent,
    AutoCompleteModule,
    CardModule,
    TabViewModule,
    BadgeModule,
    MatProgressSpinnerModule,
    AutoCompleteModule,
    DialogModule,
    CustomDatePickerModule,
    ShareExperimentComponent,
    SvgIconComponent,
    PhoTableComponent,
    PortfolioAccordionComponent,
    AccordionModule,
    ChipModule,
    PhoSidebarComponent,
    PhoSidebarFormComponent,
    ButtonPrimaryComponent,
    ButtonSecondaryComponent,
    NotFoundComponent,
    FooterNavigationComponent,
    SelectDropdownComponent,
    SliderBarComponent,
    PlanogramFooterComponent,
    MultiSelectComponent,
    EditBtnComponent,
    VersionHistoryComponent,
    OrdinalDatePipe,
    VersionConfirmComponent,
    FlowChartComponent,
    UploadFileComponent,
    MultiSliderComponent,
    EditAlertPopupComponent,
    EditModifyButtonComponent,
    ButtonToggleComponent,
    KeepButtonComponent,
    DelistButtonComponent,
    DownloadBtnComponent,
    DatePickerComponent,
    AutocompleteEmailInputComponent,
    AutoCompleteInputComponent,
    KpiCardsComponent,
    ShareButtonComponent,
    FilterPanelComponent,
    SkuDetailsComponent,
    CalendarModule,
    ExpandableTableComponent,
    ChartHighchartsComponent,
  ],
  providers: [
    ConfirmationService,
    UIChart,
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: [] },
  ],
})
export class SharedModule {}
