<p-accordion
  (activeIndexChange)="activeIndexChange($event)"
  class="w-full"
  styleClass="sku-age-accordion"
>
  <p-accordionTab>
    <ng-template pTemplate="header">
      <span class="flex justify-between w-full">
        <span>
          <span class="accordion-heading head-h3">
            {{ "PHO.SKU_AGE_ACCORDION_HEADING" | translate }}
          </span>
        </span>
        <span class="flex items-center">
          <app-svg-icon
            *ngIf="showAccordionContent"
            icon="square-minus"
            color="#000"
            padding="12px"
          ></app-svg-icon>
          <app-svg-icon
            *ngIf="!showAccordionContent"
            icon="square-plus"
            color="#000"
            padding="12px"
          ></app-svg-icon>
        </span>
      </span>
    </ng-template>
    <div class="m-0 accordion-imgs">
      <div class="flex justify-center" *ngIf="skuAge === _skuAge._6to12Months">

        <img src="/assets/images/6to12_months.svg" alt="6to12Months">
      </div>
      <div
        class="flex justify-center"
        *ngIf="skuAge === _skuAge._12to15Months"
      >
        <img src="/assets/images/12to15_img.svg" alt="12to15Months">
      </div>
      <div
        class="flex justify-center"
        *ngIf="skuAge === _skuAge._15to24Months"
      >
        <img src="/assets/images/15to24_img.svg" alt="15to24Months">
      </div>
      <div
        class="flex justify-center"
        *ngIf="skuAge === _skuAge._above24Months"
      >
        <img src="/assets/images/24more_new_img_.svg" alt="above24Months">
      </div>
    </div>
  </p-accordionTab>
</p-accordion>
